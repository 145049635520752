import styled from "styled-components";
import { mediaQuery } from "../../../utils/media-queries";

export const Footer = styled.footer`
  display: grid;
  height: 3rem;
  justify-content: center;
  align-content: center;
  background-color: #6c6061;
  height: 15vh;
  color: #ffff;

  @media ${mediaQuery.mobile} {
    display: none;
  }
`;
