import styled from "styled-components";
import { useRouter } from "next/router";
import { routes } from "../../routes";
import React from "react";
import { Header } from "./header";
import { Footer } from "./footer";

const BodyContentGrid = styled.div<{ isHomePage: boolean }>`
  display: grid;
  background-color: #ffffff;
  min-height: 70vh;
  ${(props) => props.isHomePage === true && { minHeight: "100vh" }}
`;

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();

  return (
    <>
      {router.pathname !== routes.base && (
        <Header>
          <h1 style={{ color: "#FFFFFF", fontSize: "2.5em" }}>
            {router.pathname.toUpperCase().replace("/", "")}
          </h1>
        </Header>
      )}
      <BodyContentGrid isHomePage={router.pathname === routes.base}>
        <>{children}</>
      </BodyContentGrid>
      {router.pathname !== routes.base && <Footer>Footer</Footer>}
    </>
  );
};
