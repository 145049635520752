import "../styles.css";
import React from "react";
import { Layout } from "../src/components/layout";
import Head from "next/head";
import { ApolloProvider } from "@apollo/client";
import apollocleint from "../src/graphql/apollo-client";

const MyApp = ({ Component, pageProps }) => {
  return (
    <Layout>
      <Head>
        <title>Mitch Posk Music</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="Music by Mitchell Posk" />
        <link rel="icon" type="image/x-icon" href="/static/favicon.ico" />
      </Head>
      <ApolloProvider client={apollocleint}>
        <Component {...pageProps} />
      </ApolloProvider>
    </Layout>
  );
};

export default MyApp;
