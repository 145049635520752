export const mediaQuery = {
  desktopLarge: "screen and (min-width: 1200px)",
  desktopSmall: "screen and (min-width: 950px)",
  desktop: "screen and (min-width: 813px)",
  tablet: "screen and (min-width: 700px)",
  mobileLandscape: "screen and (max-width: 812px) and (orientation: landscape)",
  mobileFullScreen: "screen and (display-mode: standalone)",
  mobile: "screen and (max-width: 812px)",
  mobileSmall: "screen and (max-width: 670px)",
  mobileTiny: "screen and (max-width: 374px)",
} as const;
